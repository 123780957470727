// https://kazupon.github.io/vue-i18n/zh/guide/lazy-loading.html
//i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCN from '@/locales/lang/zh-CN' // 加载默认本地化语言
// 组件库国际化
import mbZh from '@mybeauty/basic-bpc-component/lang/zh-CN'
// element-ui国际化
import elementZh from 'element-ui/lib/locale/lang/zh-CN'
import axios from 'axios'
import ElementLocale from 'element-ui/lib/locale'

Vue.use(VueI18n)

// 默认本地化语言
const locale = 'zh-CN'
export const i18n = new VueI18n({
  locale, // 设置语言环境
  fallbackLocale: locale,
  messages: {
    [locale]: { ...zhCN, ...mbZh, ...elementZh },
  }, // 设置语言环境信息
  silentFallbackWarn: true,
  silentTranslationWarn: true,
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

const loadedLanguages = [] // 我们的预装默认语言

function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

// 根据环境加载语言
const loadLocalLang = (lang) => {
  // 本地环境读取本地
  if (process.env.IS_VITE) {
    return import(
      /* webpackChunkName: "lang-[request]" */ `@/locales/lang/${lang}.json`
    )
  } else {
    return axios.get(
      `https://${process.env.VUE_APP_BUCKET}.cos.${
        process.env.VUE_APP_REGION
      }.myqcloud.com/cozykid-bpc-subwebapp/${
        process.env['VUE_APP_ENV']
      }/${lang}.json?t=${Date.now()}`
    )
  }
}

// 按需加载
export function loadLanguageAsync(lang) {
  lang = lang.replace('_', '-')
  // 如果语言相同
  // if (i18n.locale === lang) {
  //   return Promise.resolve(setI18nLanguage(lang))
  // }

  // 如果语言已经加载
  // if (loadedLanguages.includes(lang)) {
  //   return Promise.resolve(setI18nLanguage(lang))
  // }
  // 如果尚未加载语言
  return Promise.all([
    loadLocalLang(lang),
    import(
      /* webpackChunkName: "lang-[request]" */ `@/locales/other/${lang}.js`
    ),
  ]).then((res) => {
    const [localLang, otherLang] = res
    i18n.setLocaleMessage(lang, {
      ...(localLang.data || localLang.default),
      ...otherLang?.default,
    })
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

Vue.prototype.$loadLanguageAsync = loadLanguageAsync
