(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("BasicBpcComponent"), require("COS"), require("Compressor"), require("ELEMENT"), require("Vue"), require("VueRouter"));
	else if(typeof define === 'function' && define.amd)
		define(["BasicBpcComponent", "COS", "Compressor", "ELEMENT", "Vue", "VueRouter"], factory);
	else if(typeof exports === 'object')
		exports["cozykid-bpc-subwebapp-app"] = factory(require("BasicBpcComponent"), require("COS"), require("Compressor"), require("ELEMENT"), require("Vue"), require("VueRouter"));
	else
		root["cozykid-bpc-subwebapp-app"] = factory(root["BasicBpcComponent"], root["COS"], root["Compressor"], root["ELEMENT"], root["Vue"], root["VueRouter"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__28584__, __WEBPACK_EXTERNAL_MODULE__59635__, __WEBPACK_EXTERNAL_MODULE__57113__, __WEBPACK_EXTERNAL_MODULE__92956__, __WEBPACK_EXTERNAL_MODULE__62508__, __WEBPACK_EXTERNAL_MODULE__48533__) {
return 